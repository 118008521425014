import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { RotatingLines } from "react-loader-spinner";
import Accordion from "react-bootstrap/Accordion";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Network } from "../../../config/axios";
import {
  adminAllAppoinment,
  adminAllConsultation,
  adminAppoinment,
  generatePartnersXmlMeetingReport,
} from "../../../config/service";
import moment from "moment";
import { Button, Dropdown, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ViewDocument from "./ViewDocument";
import Comment from "./Comment";
import MeetingUpdate from "./MeetingUpdate";
import { useTranslation } from "react-i18next";
import OfferDateModal from "./OfferDate";
import ProfileDetails from "./ProfileDetails";
import MeetingLog from "./MeetingLog";
import Report from "./Report";
import IDFUpload from "./IDFUpload";
import MyDoctorUpload from "./myDoctorUpload";

let gridApi = {};
const MeetingDetails = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const gridRef = useRef([]);
  const token = localStorage.getItem("token");
  const [id, setId] = useState();
  const [clientId, setClientId] = useState();
  const [document, setDocument] = useState([]);
  const [documentModal, setDocumentModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [offerMeetingId, setoffermeetingId] = useState();
  const [newOfferDate, setDates] = useState([]);
  const [consultId, setConsultID] = useState();
  const [mstatus, setMStatus] = useState();
  const [doctorId, setDoctorId] = useState();
  const [assapValue, setAsapValue] = useState();
  const anchorRef = useRef(null);

  const handleCloseModal = () => (
    setOfferModal(false),
    getallmeetings(),
    setSDate(),
    setFDate(),
    setFTime(),
    setSTime(),
    setStartDate(),
    setStartDate1(),
    setDates([]),
    setConsultID()
  );

  const [fdate, setFDate] = useState();
  const [sdate, setSDate] = useState();
  const [ftime, setFTime] = useState();
  const [stime, setSTime] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [status, setStatus] = useState([
    { type: "Scheduled", meetings: [] },
    { type: "Created", meetings: [] },
    { type: "DatesOffered", meetings: [] },
    { type: "AdminCare", meetings: [] },
    { type: "Done", meetings: [] },
    { type: "NoShow", meetings: [] },
    { type: "Canceled", meetings: [] },
  ]);
  const [comentmeetingid, setCommentMeetingId] = useState();
  const [comment, setComment] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const handleCloseComment = () => (setCommentModal(false), getallmeetings());
  const [consultData, setConsultData] = useState([]);
  const [consultfilter, setConsultfilter] = useState([]);
  const [updateId, setUpdateId] = useState();
  const [updateData, setUpdateData] = useState([]);
  const [pacseeDocument, setPacseeDocument] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const handleClose = () => (setUpdateModal(false), getallmeetings());
  const [priceModal, setPriceModal] = useState(false);
  const priceClose = () => (setPriceModal(false), getallmeetings());
  const [offerDates, setOfferDates] = useState([]);
  const [offerData, setofferdata] = useState([]);
  const [reqdates, setReqDates] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [meetinglogId, setMeetinglogId] = useState();
  const [meetinglogmodal, setMeetinglogmodal] = useState(false);
  const handleMeetingLogModal = () => setMeetinglogmodal(false);
  const handleReportModalClose = () => setReportModal(false);
  const [detailsopen, setDetailsOpen] = useState(false);
  const [role, setRole] = useState();
  const [userid, setUserID] = useState();
  const [meetingKey, setMeetingKey] = useState(["Scheduled"]);
  const [checked, setChecked] = useState([]);
  const [loader, setLoader] = useState(true);
  const [allMeeting, setAllMeeting] = useState(false);
  const [idfModal, setIdfModal] = useState(false);
  const [myDoctorModal, setMyDoctorModal] = useState(false);
  const handleDetailsClose = () => (setDetailsOpen(false), setUserID());
  const getParams = (el) => {
    return {
      allColumns: true,
      fileName: el + "-" + moment(new Date()).format("DD-MM-YYYY hh:mm A"),
      columnKeys: [
        "Meeting Id",
        "Schedule Date",
        "Created Date",
        "Client Name",
        "Physician Name",
        "consultingField",
        "MeetingStatus",
        "Admin comments",
        "Offer Date",
        "Request Description",
        "Age",
        "Email",
        "Client Id",
        "DOB",
        "Gender",
        "Phone",
        "Marital Status",
        "Address",
      ],
    };
  };

  const handleIdfUpload = () => {
    setIdfModal(!idfModal);
  };
  const handleMyDoctorUpload = () => {
    setMyDoctorModal(!myDoctorModal);
  };
  const [columnDefs] = useState([
    { field: "Meeting Id" },
    {
      field: "Schedule Date",
      valueFormatter: (params) => {
        return params.value
          ? moment(params.value).format("DD-MM-YYYY h:mm:ss a")
          : "";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDate, cellValue) {
          filterLocalDate = new Date(filterLocalDate);
          cellValue = new Date(new Date(cellValue).setHours(0, 0, 0, 0));
          let filterBy = filterLocalDate.getTime();
          let filterMe = cellValue.getTime();
          if (filterBy === filterMe) {
            return 0;
          }

          if (filterMe < filterBy) {
            return -1;
          }

          if (filterMe > filterBy) {
            return 1;
          }
        },
      },
    },
    {
      field: "Created Date",
      refreshValuesOnOpen: true,
      sort: "desc",
      valueFormatter: (params) => {
        return params.value
          ? moment(params.value).format("DD-MM-YYYY h:mm:ss a")
          : "";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDate, cellValue) {
          filterLocalDate = new Date(filterLocalDate);
          cellValue = new Date(new Date(cellValue).setHours(0, 0, 0, 0));
          let filterBy = filterLocalDate.getTime();
          let filterMe = cellValue.getTime();
          if (filterBy === filterMe) {
            return 0;
          }

          if (filterMe < filterBy) {
            return -1;
          }

          if (filterMe > filterBy) {
            return 1;
          }
        },
      },
    },
    {
      field: "Client Name",
      onCellClicked(params) {
        setDetailsOpen(true);
        setRole("client");
        setUserID(params.data?.data?.clientData._id);
      },
    },

    {
      field: "Physician Name",
      onCellClicked(params) {
        setDetailsOpen(
          params.data?.data?.doctorData?._id ||
            params.data?.data?.offerDates[0].DoctorId
            ? true
            : false
        );
        setRole("doctor");
        setUserID(
          params.data?.data?.doctorData
            ? params.data?.data?.doctorData._id
            : params.data?.data?.offerDates[0]
            ? params.data?.data?.offerDates[0]?.DoctorId
            : null
        );
      },
    },
    { field: "consultingField" },
    {
      field: "MeetingStatus",
      cellRendererFramework: (params) => (
        <div>
          <select
            className="form-control"
            name="statuses"
            onChange={(e) => handleChangestatus(e, params.data.data._id)}
            id="statuses"
            defaultValue={params.data.MeetingStatus}
          >
            {status.map((d, i) => {
              return (
                <option key={i} value={d.type}>
                  {d.type}
                </option>
              );
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Admin comments",
      valueGetter: (params) => {
        return params.data?.Comments;
      },
      cellRendererFramework: (params) => (
        <div
          onClick={() =>
            viewComment(params.data.data._id, params.data.Comments)
          }
          className="commentColumn"
        >
          {params.data?.Comments
            ? `${params.data?.Comments.slice(0, 10)}${
                params.data?.Comments.length > 10 ? "......" : ""
              }`
            : "Add Comment"}
        </div>
      ),
    },
    {
      headerName: "View Document",
      cellRendererFramework: (params) => (
        <div>
          <Button
            onClick={() =>
              viewDocument(
                params.data.data._id,
                params.data.data.clientData._id
              )
            }
          >
            View
          </Button>
        </div>
      ),
    },
    {
      headerName: "Action",
      cellRendererFramework: (params) => (
        <div>
          <Button
            onClick={() =>
              view(
                params.data.data._id,
                params.data.data.consultation._id,
                params.data.data,
                params.data.data?.offerDates
              )
            }
          >
            Update
          </Button>
        </div>
      ),
    },
    {
      field: "Offer Date",
      headerName: "Offer Date",
      cellRendererFramework: (params) => (
        <div>
          {params.data.data?.requestedDates != 0 && !params.data.data.ASAP ? (
            <Button
              disabled={
                params.data.MeetingStatus == "Created" ||
                params.data.MeetingStatus == "DatesOffered"
                  ? false
                  : true
              }
              onClick={() =>
                openModal(
                  params.data.data._id,
                  params.data.data?.requestedDates,
                  params.data.data?.consultation._id,
                  params.data.data?.offerDates,
                  params.data.MeetingStatus,
                  params.data?.data?.requestedDates,
                  params.data?.data?.ASAP
                )
              }
            >
              Offer
            </Button>
          ) : (
            <Button
              disabled={
                params.data.MeetingStatus == "Created" ||
                params.data.MeetingStatus == "DatesOffered"
                  ? false
                  : true
              }
              onClick={() =>
                asssonpossible(
                  params.data.data._id,
                  params.data.data?.consultation._id,
                  params.data.data?.offerDates,
                  params.data.MeetingStatus,
                  params.data?.data?.requestedDates,
                  params.data?.data?.ASAP
                )
              }
            >
              Offer
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "Notification",
      filter: false,
      editable: false,
      sortable: false,
      cellRendererFramework: (params) => (
        <div>
          <input
            type="checkbox"
            className="noification_check"
            defaultChecked={params.data?.data?.isNotification ? true : false}
            onClick={(e) =>
              UpdateDetails(e.target.checked, params.data.data._id)
            }
          />
        </div>
      ),
    },
    {
      field: "Report",
      filter: false,
      editable: false,
      sortable: false,
      // width: 140,
      cellRendererFramework: (params) => (
        <>
          {params.data.data.statuses === "Done" &&
          params.data.data.partnerId?.find((element) => element === "64d326cd869f4f8664538775") ? (
            <Button
              onClick={() =>
                handleGeneratePartnersXmlReport(params.data.data._id)
              }
            >
              XML Report
            </Button>
          ) : null}
        </>
      ),
    },
    {
      field: "Meeting Logs",
      filter: false,
      editable: false,
      sortable: false,
      cellRendererFramework: (params) => (
        <div>
          <Button onClick={() => meetinglog(params.data.data._id)}>View</Button>
        </div>
      ),
    },
    { field: "Request Description", hide: true },
    { field: "Age", hide: true },
    { field: "Email", hide: true },
    { field: "Client Id", hide: true },
    {
      field: "DOB",
      hide: true,
      valueFormatter: (params) => {
        return params.value ? moment(params.value).format("DD-MM-YYYY") : "";
      },
    },
    { field: "Gender", hide: true },
    { field: "Phone", hide: true },
    { field: "Marital Status", hide: true },
    { field: "Address", hide: true },
  ]);
  const view = (id, id2, data, offerdata) => {
    const c = consultData.filter((d) => {
      return d._id == id2;
    });
    setConsultfilter(c);
    setUpdateId(id);
    setUpdateData(data);
    setofferdata(offerdata);
    setDoctorId(
      data.length != 0 && data?.DoctorId
        ? data?.DoctorId
        : data.offerDates &&
          data.offerDates.length != 0 &&
          data?.statuses !== "Scheduled"
        ? data.offerDates[0].DoctorId
        : ""
    );
    setUpdateModal(true);
  };
  const openModal = (
    id,
    dates,
    cid,
    offerdate,
    meetingstatus,
    requesteddates,
    asapvalue
  ) => {
    setoffermeetingId(id);
    setDates(dates);
    setOfferDates(offerdate);
    setConsultID(cid);
    setMStatus(meetingstatus);
    setReqDates(requesteddates);
    setAsapValue(asapvalue);
    setOfferModal(true);
  };
  const asssonpossible = (
    id,
    cid,
    offerdate,
    meetingstatus,
    requesteddates,
    asapvalue
  ) => {
    setoffermeetingId(id);
    setOfferDates(offerdate);
    setConsultID(cid);
    setMStatus(meetingstatus);
    setReqDates(requesteddates);
    setOfferModal(true);
    setAsapValue(asapvalue);
  };

  const viewComment = (id, data) => {
    setCommentMeetingId(id);
    setComment(data);
    setCommentModal(true);
  };

  const consultingFields = async () => {
    try {
      const response = await adminAllConsultation();
      setConsultData(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const allDocument = async (id) => {
    try {
      const response = await Network().get(
        `/meeting/meetingDocumentsByMeetingId/${id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if ([200, 201, 202].includes(response.data.code)) {
        setDocument(response.data.meetingDocuments);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewDocument = async (id, id2) => {
    setId(id);
    setClientId(id2);
    setDocumentModal(true);
    allDocument(id);
    mridocument(id);
  };
  const mridocument = async (id) => {
    try {
      const response = await Network().get(
        `/users/pacseeMeetingDocuments?meetingId=${id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      setPacseeDocument(response.data.pacseeFiles);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangestatus = (event, id) => {
    let targetValue = event.target.value;
    statusUpload(targetValue, id);
  };
  const statusUpload = async (targetValue, id) => {
    const data = {
      statuses: targetValue,
    };
    try {
      const response = await Network().patch(
        "/admin/updateAppointmentById/" + id,
        data,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        toast.success(response.data.msg);
        getallmeetings();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const meetinglog = (id) => {
    setMeetinglogId(id);
    setMeetinglogmodal(true);
  };
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: true,
    resizable: true,
    floatingFilter: true,
    flex: 1,
  }));

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const handleAllMeeting = () => {
    if (allMeeting) {
      setAllMeeting(false);
    } else {
      setAllMeeting(true);
    }
  };

  useEffect(() => {
    consultingFields();
    getallmeetings();
  }, [allMeeting]);
  const getallmeetings = async () => {
    let data = {
      page: 1,
      perPage: 1000,
    };
    setLoader(true);
    const response = await (allMeeting
      ? adminAllAppoinment(data)
      : adminAppoinment(data));

    const meetingData = (
      allMeeting ? response?.data?.result : response?.data?.result?.result
    )?.map((d) => {
      return {
        "Meeting Id": d._id,
        "Created Date": new Date(d.createdAt),
        "Schedule Date": d?.scheduledDate ? new Date(d?.scheduledDate) : null,
        "Client Name": `${
          d.clientData?.first_name ? d?.clientData?.first_name : ""
        } ${d?.clientData?.last_name ? d?.clientData?.last_name : ""}`,
        "Physician Name":
          d?.doctorData?.first_name && d?.doctorData?.first_name
            ? `${d?.doctorData?.first_name ? d?.doctorData?.first_name : ""} ${
                d?.doctorData?.last_name ? d?.doctorData?.last_name : ""
              }`
            : d?.offerDates.length != 0 &&
              d?.offerDates[0]?.doctorUserDetails &&
              d?.statuses !== "Scheduled"
            ? `${d?.offerDates[0]?.doctorUserDetails[0]?.first_name} ${d?.offerDates[0]?.doctorUserDetails[0]?.last_name}`
            : null,
        consultingField:
          lang === "EN" ? d?.consultation?.eng : d?.consultation?.heb,
        // MeetingId: d._id,
        MeetingStatus: d.statuses,
        Comments: d.comments,
        ClientId: d.ClientId,
        data: d,
        "Request Description": d?.requestDescription,
        Age: ageCalculator(d?.meetingpatientdetails?.patientDOB),
        Email: d?.meetingpatientdetails?.patientEmail,
        "Client Id": d?.ClientId,
        DOB: moment(d?.meetingpatientdetails?.patientDOB).format("DD-MM-YYYY"),
        Gender: d?.clientData?.clientGender,
        Phone: d?.meetingpatientdetails?.patientPhone,
        "Marital Status": d?.meetingpatientdetails?.patientMaritalStatus,
        Address: d?.clientData?.clientAddress?.country,
      };
    });
    let statusKey = [...status];
    statusKey.forEach((statusSubKey) => {
      statusSubKey.meetings = meetingData?.filter(
        (el) => el.MeetingStatus === statusSubKey.type
      );
    });
    setStatus(statusKey);
    setLoader(false);
  };
  const rowClassRules = useMemo(() => {
    return {
      "admin-prescription-only": (params) => {
        const status = params.data.data.statuses;
        const type = params.data.data.meetingType;
        return status === "Done" && type === "PrescriptionOnly";
      },
    };
  }, []);
  const UpdateDetails = async (checkedValue, meetingid) => {
    const data = {
      isNotification: checkedValue,
    };
    try {
      const response = await Network().patch(
        "/admin/updateAppointmentById/" + meetingid,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        toast.success(t(response.data.msg));
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onGridReady = useCallback((params, i) => {
    gridApi[i] = params.api;
  }, []);
  const onExportClick = useCallback(() => {
    checked.forEach((el) => {
      if (status.find((s) => s.type === el)) {
        gridApi[el].exportDataAsCsv(getParams(el));
      }
    });
    setChecked([]);
  }, [checked]);

  const handleSelectDownload = (type) => {
    setChecked((prevSelected) => {
      const newArray = [...prevSelected];
      if (newArray.includes(type)) {
        return newArray.filter((item) => item != type);
      } else {
        newArray.push(type);
        return newArray;
      }
    });
  };

  const sortMeeting = (key) => {
    if (meetingKey.includes(key)) {
      let m = meetingKey.filter((ml) => ml !== key);
      setMeetingKey(m);
    } else {
      let m = [...meetingKey];
      m.push(key);
      setMeetingKey(m);
    }
  };

  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    Object.keys(gridApi).forEach((el) => {
      gridApi[el].setQuickFilter(value);
      if (gridApi[el].isQuickFilterPresent() && !meetingKey.includes(el)) {
        setMeetingKey((m) => {
          m = m.concat([el]);
          return m;
        });
      }
    });
  };

  const handleGeneratePartnersXmlReport = async (meetingId) => {
    const data = {
      meetingId: meetingId,
    };

    try {
      const response = await generatePartnersXmlMeetingReport(data);

      const { status } = response;

      if (status === 201) {
        if (response.data.message) {
          toast.error(t(response.data.message));
        } else {
          const outputFilename = `${meetingId}-Ichilov.xml`;
          downloadXmlFile(response.data, outputFilename);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadXmlFile = (xmlData, outputFilename) => {
    const blob = new Blob([xmlData], { type: "text/xml" });
    const url = window.URL.createObjectURL(blob);
    anchorRef.current.href = url;
    anchorRef.current.setAttribute("download", outputFilename);
    anchorRef.current.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <a ref={anchorRef} style={{ display: "none" }} />
      <ProfileDetails
        detailsopen={detailsopen}
        role={role}
        userid={userid}
        handleDetailsClose={handleDetailsClose}
      />
      <OfferDateModal
        offerModal={offerModal}
        offerMeetingId={offerMeetingId}
        handleCloseModal={handleCloseModal}
        newOfferDate={newOfferDate}
        fdate={fdate}
        setFDate={setFDate}
        sdate={sdate}
        setSDate={setSDate}
        ftime={ftime}
        setFTime={setFTime}
        stime={stime}
        setSTime={setSTime}
        startDate={startDate}
        setStartDate={setStartDate}
        startDate1={startDate1}
        setStartDate1={setStartDate1}
        consultId={consultId}
        offerDates={offerDates}
        mstatus={mstatus}
        requestedDates={reqdates}
        assapValue={assapValue}
      />
      <MeetingLog
        handleClose={handleMeetingLogModal}
        meetinglogModal={meetinglogmodal}
        meetingId={meetinglogId}
      />
      <Comment
        comentmeetingid={comentmeetingid}
        comment={comment}
        commentModal={commentModal}
        handleClose={handleCloseComment}
      />
      {id && documentModal && (
        <ViewDocument
          documentModal={documentModal}
          document={document}
          setDocumentModal={setDocumentModal}
          meetingid={id}
          clientId={clientId}
          pacseeDocument={pacseeDocument}
          setPacseeDocument={setPacseeDocument}
        />
      )}
      <MeetingUpdate
        consultfilter={consultfilter}
        updateId={updateId}
        updateData={updateData}
        updateModal={updateModal}
        setUpdateModal={setUpdateModal}
        handleClose={handleClose}
        consultData={consultData}
        priceClose={priceClose}
        priceModal={priceModal}
        setPriceModal={setPriceModal}
        offerDates={offerData}
        doctorId={doctorId}
        setDoctorId={setDoctorId}
      />
      <Report
        handleReportModalClose={handleReportModalClose}
        reportModal={reportModal}
      />
      <IDFUpload
        idfModal={idfModal}
        handleIdfUpload={handleIdfUpload}
        getallmeetings={getallmeetings}
      />
      {myDoctorModal && (
        <MyDoctorUpload
          myDoctorModal={myDoctorModal}
          setMyDoctorModal={setMyDoctorModal}
          handleMyDoctorUpload={handleMyDoctorUpload}
        />
      )}

      <div
        className="adminDataTable"
        // style={{ paddingTop: "13%", background: "#f1f1f1" }}
      >
        <div className="table-header-section">
          <Button
            onClick={() => setReportModal(true)}
            className="download_report"
          >
            Download Report
          </Button>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">Export to CSV</Dropdown.Toggle>

            <Dropdown.Menu>
              {status.map((e, i) => {
                const isSelected = checked.includes(e.type);
                return (
                  <div
                    className="dropdown-item"
                    key={i}
                    onClick={() => handleSelectDownload(e.type)}
                  >
                    <Form.Check
                      type="checkbox"
                      aria-label="option 1"
                      label={e.type}
                      checked={isSelected}
                    />
                  </div>
                );
              })}
              <div className="download-btn">
                <Button onClick={() => onExportClick()}>Download</Button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={() => handleAllMeeting()}
            className="download_report"
            disabled={loader}
          >
            {allMeeting ? "Limited Meeting" : "All Meetings"}
          </Button>
          <Button onClick={() => handleIdfUpload()} className="download_report">
            IDF Upload
          </Button>
          <Button
            onClick={() => handleMyDoctorUpload()}
            className="download_report"
          >
            myDoctor Upload
          </Button>
          <input
            id="filter-text-box"
            placeholder="Search"
            onInput={onFilterTextBoxChanged}
            className="global-search"
            type="search"
          />
        </div>

        <Accordion
          defaultActiveKey={status[0].type}
          onSelect={(key) => sortMeeting(key)}
          alwaysOpen
        >
          <div className="accrdion-list-grid">
            {status.map((e, i) => (
              <div className="accrdion-list" key={e.type}>
                <Accordion.Item eventKey={`${e.type}`} key={e.type}>
                  <Accordion.Header>{e.type}</Accordion.Header>
                  <Accordion.Body>
                    <div
                      className="ag-theme-alpine"
                      style={{ width: "100%", height: 450 }}
                    >
                      {loader ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="48"
                            visible={true}
                          />
                        </div>
                      ) : (
                        <AgGridReact
                          ref={(el) => (gridRef.current[i] = el)}
                          rowData={e.meetings}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          animateRows={true}
                          rowSelection="multiple"
                          onCellClicked={cellClickedListener}
                          alwaysShowHorizontalScroll={true}
                          alwaysShowVerticalScroll={true}
                          onGridReady={(params) => onGridReady(params, e.type)}
                          rowClassRules={rowClassRules}
                        />
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            ))}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default MeetingDetails;

export function ageCalculator(d) {
  var dob = new Date(d);
  var month_diff = Date.now() - dob.getTime();
  var age_dt = new Date(month_diff);
  var year = age_dt.getUTCFullYear();
  var age = Math.abs(year - 1970);
  return age;
}
