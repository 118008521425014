import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import MyDyteMeeting from "./dyteMeeting";
import { toast } from "react-toastify";
import { getUserDyteToken } from "../../config/service";

const DyteMeetingConatiner = ({ mId }) => {
  const [meeting, initMeeting] = useDyteClient();

  const { meetingId } = useParams();
  const fetchUserDyteAuthToken = async () => {
    const payload = {
      meetingId: mId ? mId : meetingId,
    };

    await getUserDyteToken(payload)
      .then((tokenResponse) => {
        if (tokenResponse?.data?.code === 200) {
          initMeeting({
            authToken: tokenResponse?.data?.token,
            defaults: {
              audio: true,
              video: true,
            },
          });
        } else {
          toast.error(tokenResponse?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  useEffect(() => {
    fetchUserDyteAuthToken();
  }, []);

  return (
    <DyteProvider value={meeting} fallback={<i>Loading...</i>}>
      <MyDyteMeeting />
    </DyteProvider>
  );
};

export default DyteMeetingConatiner;
