import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import "./client.css";
import "react-image-crop/dist/ReactCrop.css";
import { getAllAppointment } from "../../config/service";
import ClientPastRequest from "./ClientPastRequest";
import ClientMyRequest from "./ClientMyRequest";
import ClientFutureMeeting from "./ClientFutureMeeting";
import { useTranslation } from "react-i18next";
import { Network } from "../../config/axios";
import ApproveTermsModal from "./ApproveTermsModal";

const PatientDashboard = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  const mode = localStorage.getItem("mode");
  const [allAppointment, setAllAppointment] = useState([]);
  const [createdList, setCreatedList] = useState([]);
  const [meetingValue, setMeetingValue] = useState(mode ==="IDF" ? "future" : "DatesOffered");
  const [cancelModalOpen, setCancelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [show, setShow] = useState(false);
  const [medicalq, setMedicalQ] = useState(false);
  const [qData, setQData] = useState();
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [mid, setMId] = useState();
  const [iprice, setPrice] = useState();
  const [showIframe, setShowIframe] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [medInfo, setMedInfo] = useState(false);

  const getTermsAndConditions = async () => {
    try {
      const response = await Network().get(`/users/getTermsAndConditions`, {
        headers: {
          authorization: token,
        },
      });
      const {
        data: { statusCode, termsAndConditions },
      } = response;
      if (statusCode === 200 && !termsAndConditions) {
        setShowTermsModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const pacseeClose = () => (
    setShowIframe(false),
    setShow(false),
    AllApoinment({ val: "future" }),
    setMeetingValue("future")
  );

  const [uploadModal, setUploadModal] = useState(false);
  const cropimageClose = () => (
    setUploadModal(false),
    AllApoinment({ val: "future" }),
    setMeetingValue("future")
  );
  const handleClose5 = () => (
    setMedicalQ(false),
    AllApoinment({ val: "future" }),
    setQData({}),
    setMeetingValue("future")
  );
  const handleClose6 = () => (
    setMedicalQ(false),
    AllApoinment({ val: "past" }),
    setQData({}),
    setMeetingValue("past")
  );
  const handleClose3 = () => (
    setShow(false),
    AllApoinment({ val: "DatesOffered" }),
    setMId(),
    setPrice(),
    setMeetingValue("DatesOffered")
  );
  const handleClose = () => (
    setCancelModal(false),
    AllApoinment({ val: "DatesOffered" }),
    created({ val: "Created" }),
    setMeetingValue("DatesOffered")
  );
  const handleClose2 = () => (
    setUpdateModal(false),
    created({ val: "Created" }),
    setMeetingValue("DatesOffered")
  );

  useEffect(() => {
    if(mode ==="IDF") {
      AllApoinment({ val: "future" });
      setMeetingValue("future")
    }else {
      AllApoinment({ val: "DatesOffered" });
      created({ val: "Created" });
    }
 
  }, []);
  const created = async ({ val }) => {
    setLoader1(true);
    const data = {
      meeting_status: val,
    };
    const response = await getAllAppointment(data);
    setCreatedList(response.data.result);
    setLoader1(false);
  };
  const AllApoinment = async ({ val }) => {
    setLoader(true);
    const data = {
      meeting_status: val,
    };

    const response = await getAllAppointment(data);
    setAllAppointment(response.data.result);
    setLoader(false);
  };
  const handleRedirectPast = () => {
    AllApoinment({ val: "past" }),
      setMeetingValue("past"),
      localStorage.setItem("status", "past");
  };
  const handleTabClick = (value) => {
    if (value === "DatesOffered") {
      created({ val: "Created" });
    }
    AllApoinment({ val: value });
    setMeetingValue(value);
    localStorage.setItem("status", value);
  };
  const handleCancelPartnerOffer = () => {
    setMedInfo(false);
    AllApoinment({ val: "DatesOffered" });
    created({ val: "Created" });
  };

  return (
    <div className="patient-dashboard">
      <ApproveTermsModal show={showTermsModal} setShow={setShowTermsModal} />
      <section className={`statusWrapper ${mode === "IDF" ? "idf-mode" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="innerWrapper">
                <div
                  className={`${lang === "HE" ? "itemBoxHeb" : "itemBoxEng"} ${
                    meetingValue == "past" ? "activeDashTab" : ""
                  }`}
                  onClick={() => handleTabClick("past")}
                >
                  {lang === "HE" ? (
                    <h2>
                      {t("APPOINTMENTS")} <strong> {t("MY PAST")}</strong>
                    </h2>
                  ) : (
                    <h2>
                      <strong> {t("MY PAST")}</strong> {t("APPOINTMENTS")}
                    </h2>
                  )}
                </div>
                {mode !== "IDF" ? 
                <div
                  className={`${lang === "HE" ? "itemBoxHeb" : "itemBoxEng"} ${
                    meetingValue == "DatesOffered" ? "activeDashTab" : ""
                  }`}
                  onClick={() => handleTabClick("DatesOffered")}
                >
                  <h2>
                    <strong>{t("MY REQUESTS")}</strong> {t("STATUS")}
                  </h2>
                </div>
                 : null}
                <div
                  className={`${lang === "HE" ? "itemBoxHeb" : "itemBoxEng"} ${
                    meetingValue == "future" ? "activeDashTab" : ""
                  }`}
                  onClick={() => handleTabClick("future")}
                >
                  {lang === "HE" ? (
                    <h2>
                      {t("APPOINTMENTS")} <strong> {t("MY FUTURE")}</strong>
                    </h2>
                  ) : (
                    <h2>
                      <strong> {t("MY FUTURE")}</strong> {t("APPOINTMENTS")}
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {meetingValue == "future" ? (
        <ClientFutureMeeting
          allAppointment={allAppointment}
          handleClose5={handleClose5}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          loader={loader}
          cropimageClose={cropimageClose}
          uploadModal={uploadModal}
          setUploadModal={setUploadModal}
          mode={mode}
          meetingValue={meetingValue}
          showTermsModal={showTermsModal}
        />
      ) : meetingValue == "DatesOffered" && mode !=="IDF" ? (
        <ClientMyRequest
          allAppointment={allAppointment}
          createdList={createdList}
          handleClose={handleClose}
          cancelModalOpen={cancelModalOpen}
          setCancelModal={setCancelModal}
          handleClose2={handleClose2}
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          handleClose3={handleClose3}
          show={show}
          setShow={setShow}
          loader={loader}
          loader1={loader1}
          mid={mid}
          setMId={setMId}
          iprice={iprice}
          setPrice={setPrice}
          pacseeClose={pacseeClose}
          showIframe={showIframe}
          setShowIframe={setShowIframe}
          handleRedirectPast={handleRedirectPast}
          setMedInfo={setMedInfo}
          medInfo={medInfo}
          handleCancelPartnerOffer={handleCancelPartnerOffer}
        />
      ) : meetingValue == "past" ? (
        <ClientPastRequest
          allAppointment={allAppointment}
          handleClose5={handleClose6}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          loader={loader}
          mode={mode}
        />
      ) : (
        <ClientPastRequest
          allAppointment={allAppointment}
          handleClose5={handleClose6}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          loader={loader}
          mode={mode}
        />
      )}
    </div>
  );
};
export default PatientDashboard;
