import React, { useEffect, useState, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import { userGetUserProfile, MeetingPrice } from "../../config/service";
import { Network } from "../../config/axios";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { SelectDatepicker } from "react-select-datepicker";
import $ from "jquery";
import { handleKeyPress } from "../Login/login";
import jwt_decode from "jwt-decode";
import { checkEmailFormate } from "../../utils/function";

let mData;

const ConfirmationDetails = ({ formData, setformData, doctorObj, utm }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [refStep] = useState(searchParams.get("mId"));
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  const [otp, setOtp] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [priceamount, setPriceAmount] = useState();
  const [insuranceConsent, setInsuranceConsent] = useState(false);
  const [value, setValue] = useState();
  const [bisablebutton, setDisableButton] = useState(false);
  const [meetingLoader, setMeetingLoader] = useState(false);
  const [pvalue, setPValue] = useState();
  const [partnerId, setPartnerId] = useState([]);

  const getDoctorId = async () => {
    mData = JSON.parse(localStorage.getItem("meetData"));
  };
  useEffect(() => {
    if (refStep) {
      getDoctorId();
    }
  }, []);

  const map1 = new Map();
  map1.set("Morning", "בוקר");
  map1.set("Afternoon", "צהרים");
  map1.set("Evening", "ערב");
  useEffect(() => {
    getmeetingPrice();
    userDetails();
  }, []);
  const onDateChange = useCallback((date) => {
    setValue(date);
    var today = new Date();
    var birthDate = new Date(date); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    if (
      (date && formData.age === 1 && age_now > 18) ||
      (date && formData.age === 18 && age_now < 18)
    ) {
      setformData((prev) => ({
        ...prev,
        confirmation: {
          ...prev.confirmation,
          overEighteenStatus: false,
          underEighteenStatus: true,
        },
      }));
    }
  }, []);
  const onPDateChange = useCallback((date) => {
    setPValue(date);
  }, []);
  const userDetails = async () => {
    const data = {
      userId: localStorage.getItem("userId"),
    };

    if (data.userId) {
      const response = await userGetUserProfile(data);
      setInsuranceConsent(response?.data?.profileDetails?.insuranceConsent);
      var today = new Date();
      var birthDate = new Date(response.data.profileDetails.clientDOB); // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      if (
        (formData.age === 1 && age_now > 18) ||
        (formData.age === 18 && age_now < 18)
      ) {
        setformData((prev) => ({
          ...prev,
          confirmation: {
            ...prev.confirmation,
            overEighteenStatus: false,
            underEighteenStatus: true,
          },
        }));
      }
    }
  };
  const handleConfirmation = (e) => {
    if (
      (e.target.name === "overEighteenStatus" && e.target.checked === true) ||
      (e.target.name === "underEighteenStatus" && e.target.checked === false)
    ) {
      setformData((prev) => ({
        ...prev,
        confirmation: {
          ...prev.confirmation,
          overEighteenStatus: true,
          underEighteenStatus: false,
        },
      }));
    } else if (
      (e.target.name === "overEighteenStatus" && e.target.checked === false) ||
      (e.target.name === "underEighteenStatus" && e.target.checked === true)
    ) {
      setformData((prev) => ({
        ...prev,
        confirmation: {
          ...prev.confirmation,
          overEighteenStatus: false,
          underEighteenStatus: true,
        },
      }));
    } else
      setformData((prev) => ({
        ...prev,
        confirmation: {
          ...prev.confirmation,
          [e.target.name]: e.target.checked,
        },
      }));
  };

  const handleSetPartnerId = () => {
    setPartnerId([formData.partnerId]);
  };

  const handleMadnessConfirmation = (e) => {
    const { name, checked } = e.target;
    if (name === "madnessConsent") {
      if (checked === true && !partnerId.includes("66040f5e6b8f8dd2149bf3a3")) {
        setPartnerId([...partnerId, "66040f5e6b8f8dd2149bf3a3"]);
      } else if (
        checked === false &&
        partnerId.includes("66040f5e6b8f8dd2149bf3a3")
      ) {
        setPartnerId(
          partnerId.filter((id) => id !== "66040f5e6b8f8dd2149bf3a3")
        );
      }
    }
  };

  useEffect(() => {
    if (formData.partnerId) {
      handleSetPartnerId();
    }
  }, [formData.partnerId]);
  const handleUserConfirmation = (e) => {
    if (e.target.value === "Select" || e.target.value === "בחר") {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [e.target.name]: "" },
      }));
    } else {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [e.target.name]: e.target.value },
      }));
    }
  };

  const handleUserConfirmationPhone = (e) => {
    const { name, value } = e.target;
    setPhoneNumber(value);
    if (
      value.startsWith("+") &&
      !isNaN(value.substring(1, value.length)) &&
      !value.endsWith(".")
    ) {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [name]: value.trim() },
      }));
    } else if (!isNaN(value) && !value.endsWith(".")) {
      setformData((prev) => ({
        ...prev,
        confirmation: { ...prev.confirmation, [name]: value.trim() },
      }));
    }
  };

  const getmeetingPrice = async () => {
    const response = await MeetingPrice();
    setPriceAmount(response.data?.defaultPrice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      if (!formData?.confirmation?.id) {
        toast.error(t("Please enter id number"));
        return;
      }
      if (!formData?.confirmation?.first_name) {
        toast.error(t("Please enter first name"));
        return;
      }
      if (!formData?.confirmation?.last_name) {
        toast.error(t("Please enter last name"));
        return;
      }
      if (!formData?.confirmation?.phone) {
        toast.error(t("Please enter mobile number"));
        return;
      }
      if (!formData?.confirmation?.email) {
        toast.error(t("Please enter valid email"));
        return;
      }
      if (formData?.confirmation?.email) {
        const isEmailCorrect = checkEmailFormate(formData?.confirmation?.email);
        if (!isEmailCorrect) {
          toast.error("Please enter valid email");
          return;
        }
      }
      if (!formData?.confirmation?.gender) {
        toast.error(t("Please select gender"));
        return;
      }
      if (!value) {
        toast.error(t("Please choose date of birth"));
        return;
      }
    }
    if (!formData?.confirmation?.zoomStatus) {
      toast.error(
        t(
          "It must be confirmed that the Zoom application is installed on the device from which you will make the call"
        )
      );
      return;
    } else if (!formData?.confirmation?.consentDocument) {
      toast.error(
        t("The terms of consent of medical information must be approved")
      );
      return;
    } else if (!formData?.confirmation?.termsAndConditions) {
      toast.error(
        t(
          "The terms of service and delivery of medical information must be approved"
        )
      );
      return;
    } else if (!formData?.confirmation?.privacyPolicy) {
      toast.error(t("The terms of Privacy Policy must be approved"));
      return;
    }

    if (formData?.confirmation?.underEighteenStatus) {
      if (!formData?.confirmation?.pid) {
        toast.error(t("The patient ID number must be filled in"));
        return;
      } else if (!formData?.confirmation?.pfirst_name) {
        toast.error(t("The first name of the patient must be filled in"));
        return;
      } else if (!formData?.confirmation?.plast_name) {
        toast.error(t("The last name of the patient must be filled in"));
        return;
      } else if (!pvalue) {
        toast.error(t("Please choose date of birth"));
        return;
      } else if (!formData?.confirmation?.pgender) {
        toast.error(t("Please select patient gender"));
        return;
      }
    }
    window.gtag("event", "new-request-submit", {});
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const collective = params.collective;
    if (collective) {
      formData["collective"] = collective;
    }
    // setOpen1(true);
    confirm();
  };
  const meetingRequest = async () => {
    setMeetingLoader(true);
    setOpen(false);
    const token = localStorage.getItem("token");
    // ayalonConfirmation
    try {
      const data = {
        requestedDates: formData?.time,
        requestDescription: formData?.reference,
        service: "video",
        consultingFieldId: formData?.consult?._id,
        isUrgent: false,
        termsAndConditions: formData?.confirmation?.termsAndConditions,
        insuranceConsent: insuranceConsent
          ? insuranceConsent
          : formData?.confirmation?.insuranceConsent,
        age: formData?.age,
        issueInvoice: formData?.confirmation?.issueInvoice
          ? formData?.confirmation?.issueInvoice
          : false,
        patientDetails: {
          first_name: formData?.confirmation?.pfirst_name
            ? formData?.confirmation?.pfirst_name
            : "",
          last_name: formData?.confirmation?.plast_name
            ? formData?.confirmation?.plast_name
            : "",
          patientDOB: moment(pvalue).toDate() ? moment(pvalue).toDate() : "",
          id: formData?.confirmation?.pid ? formData?.confirmation?.pid : "",
          patientGender: formData?.confirmation?.pgender
            ? formData?.confirmation?.pgender != "Other"
              ? formData?.confirmation?.pgender
              : "N/A"
            : "",
        },
        doctorId: mData?.DoctorId || doctorObj?.userDetails?._id,
        refferalId: localStorage.getItem("refferalId"),
        meetingCreatorDetails: {
          first_name: formData?.confirmation?.first_name
            ? formData?.confirmation?.first_name
            : "",
          last_name: formData?.confirmation?.last_name
            ? formData?.confirmation?.last_name
            : "",
          clientDOB: moment(value).toDate() ? moment(value).toDate() : "",
          id: formData?.confirmation?.id ? formData?.confirmation?.id : "",
          clientGender: formData?.confirmation?.gender
            ? formData?.confirmation?.gender
            : "",
          userphone: formData?.confirmation?.phone
            ? formData?.confirmation?.phone
            : "",
          email: formData?.confirmation?.email
            ? formData?.confirmation?.email
            : "",
          UTM: localStorage.getItem("utm"),
          collective: localStorage.getItem("collective"),
        },
        // partnerId: "64d326cd869f4f8664538775",
      };


      console.log('partnerId', partnerId);
      if (partnerId.length > 0) {
        data.partnerId = partnerId;
      }

      let response;
      if (!refStep) {
        response = await Network().post(
          "/meeting/createAppointment",
          data,
          token && {
            headers: {
              authorization: token,
              "Content-Type": "Application/json",
            },
          }
        );
      } else {
        response = await Network().post(
          "/meeting/createFollowUpAppointment",
          data,
          token && {
            headers: {
              authorization: token,
              "Content-Type": "Application/json",
            },
          }
        );
      }
      const {
        data: { code, msg },
      } = response || {};
      if ([200, 201, 202].includes(code) || response.status == 201) {
        toast.success(t(msg));
        if (token) {
          if (partnerId.includes("66040f5e6b8f8dd2149bf3a3")) {
            navigate("/meeting/thank-you", {
              state: { madnessAlertId: "66040f5e6b8f8dd2149bf3a3" },
            });
          } else {
            navigate("/meeting/thank-you");
          }
        }
        // otpSwal(formData?.confirmation?.phone, formData, navigate);/
      } else {
        toast.error(t(msg));
      }
      setMeetingLoader(false);
      setOpen1(false);
    } catch (error) {
      console.log(error);
      setMeetingLoader(false);
      setOpen1(false);
    }
  };

  const verifyOtp = async () => {
    if (otp) {
      setDisableButton(true);
      const data = {
        otp: otp,
        phone: formData.confirmation.phone,
        role: "client",
        id: formData?.confirmation?.id,
      };
      try {
        // make axios post request
        const response = await Network().post(
          "/meeting/verifyMeetingOtp",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if ([200, 201, 202].includes(response.status)) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);

          const decodedToken = jwt_decode(response.data.token);
          localStorage.setItem("userName", decodedToken.name);
          localStorage.setItem("userId", decodedToken.id);
          localStorage.setItem("role", decodedToken.role);
          meetingRequest();
          setDisableButton(false);
        } else {
          toast.error(t(response.data.msg));
        }
      } catch (error) {
        toast.error(t(error.response.data.message));
        setOtp();
        setDisableButton(false);
        console.log(error);
        //   setErrorMsg(true);
      }
    } else {
      toast.error(t("Please Type OTP"));
    }
  };

  const confirm = () => {
    if (token) {
      meetingRequest();
    } else {
      meetingRequest();
      if (token) {
        toast.success(
          t(
            "A one-time code was sent in a message to the phone number you entered"
          )
        );
      }
      setOpen(true);
    }
  };
  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));
  $(function () {
    $("#id_input").on("keypress", function (e) {
      if (e.which == 32) {
        return false;
      }
    });
  });
  $(function () {
    $("#mobile_input").on("keypress", function (e) {
      if (e.which == 32) {
        return false;
      }
    });
  });
  $(function () {
    $("#pid_input").on("keypress", function (e) {
      if (e.which == 32) {
        return false;
      }
    });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="confirmation-details-section">
      <div className="section-heading confirmation">
        <h6>{t("Account Holder")}</h6>
      </div>
      <Form>
        <div
          className={`${"confirmation-agree-section"} ${lang === "EN" ? "textleft" : "textright"
            }`}
        >
          {!token ? (
            <>
              <div className="confirmation-input-section">
                <Form.Group className="form-group">
                  <Form.Control
                    className={"ltrForce"}
                    id="id_input"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder={t("Id Number")}
                    name="id"
                    onKeyPress={handleKeyPress}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 14))
                    }
                    value={
                      formData?.confirmation?.id
                        ? formData?.confirmation?.id
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    id="a"
                    type="text"
                    placeholder={t("First Name")}
                    name="first_name"
                    value={
                      formData?.confirmation?.first_name
                        ? formData?.confirmation?.first_name
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    id="a"
                    type="text"
                    placeholder={t("Last Name")}
                    name="last_name"
                    value={
                      formData?.confirmation?.last_name
                        ? formData?.confirmation?.last_name
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Control
                    id="mobile_input"
                    type="text"
                    placeholder={t("Phone Number")}
                    name="phone"
                    className="phone_number"
                    value={
                      formData?.confirmation?.phone
                        ? formData?.confirmation?.phone
                        : ""
                    }
                    onChange={handleUserConfirmationPhone}
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Control
                    type="email"
                    placeholder={t("Email")}
                    name="email"
                    value={
                      formData?.confirmation?.email
                        ? formData?.confirmation?.email
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <div className="choose-gender-grid">
                    <Form.Check
                      type={"radio"}
                      label={t("Male")}
                      value={
                        formData?.confirmation?.gender
                          ? formData?.confirmation?.gender
                          : "Male"
                      }
                      name="gender"
                      onChange={handleUserConfirmation}
                    />
                    <Form.Check
                      type={"radio"}
                      label={t("Female")}
                      value={
                        formData?.confirmation?.gender
                          ? formData?.confirmation?.gender
                          : "Female"
                      }
                      name="gender"
                      onChange={handleUserConfirmation}
                    />
                  </div>
                  {/* <select
                    className="form-control selectContainer"
                    id="a"
                    type="text"
                    placeholder=""
                    name="gender"
                    value={
                      formData?.confirmation?.gender
                        ? formData?.confirmation?.gender
                        : ""
                    }
                    onChange={handleUserConfirmation}
                  >
                    <option>{t("Select")}</option>
                    <option value={"Male"}>{t("Male")}</option>
                    <option value={"Female"}>{t("Female")}</option>
                  </select> */}
                </Form.Group>

                <Form.Group>
                  <Form.Label> {t("Date of Birth")}</Form.Label>
                  <SelectDatepicker
                    order="day/month/year"
                    selectedDate={value}
                    onDateChange={onDateChange}
                    className="dob-picker"
                    monthPlaceholder={"Month"}
                  />
                </Form.Group>
              </div>
            </>
          ) : null}

          <>
            <Form.Check
              type="checkbox"
              label={t("The patient is not the account holder")}
              className={
                lang === "EN" ? "checkleft p-check" : "checkright p-check"
              }
              checked={
                formData?.confirmation?.underEighteenStatus
                  ? formData?.confirmation?.underEighteenStatus
                  : false
              }
              onChange={handleConfirmation}
              name="underEighteenStatus"
            />
            {formData?.confirmation?.underEighteenStatus && (
              <>
                <div className="confirmation-input-section">
                  <Form.Group className="form-group">
                    <Form.Control
                      id="pid_input"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder={t("ID. of patient")}
                      name="pid"
                      onKeyPress={handleKeyPress}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 14))
                      }
                      value={
                        formData?.confirmation?.pid
                          ? formData?.confirmation?.pid
                          : ""
                      }
                      onChange={handleUserConfirmation}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      id="a"
                      type="text"
                      placeholder={t("First Name")}
                      name="pfirst_name"
                      value={
                        formData?.confirmation?.pfirst_name
                          ? formData?.confirmation?.pfirst_name
                          : ""
                      }
                      onChange={handleUserConfirmation}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      id="a"
                      type="text"
                      placeholder={t("Last Name")}
                      name="plast_name"
                      value={
                        formData?.confirmation?.plast_name
                          ? formData?.confirmation?.plast_name
                          : ""
                      }
                      onChange={handleUserConfirmation}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label> {t("Gender")}</Form.Label>
                    <div className="choose-gender-grid">
                      <Form.Check
                        type={"radio"}
                        label={t("Male")}
                        value={
                          formData?.confirmation?.pgender
                            ? formData?.confirmation?.pgender
                            : "Male"
                        }
                        name="pgender"
                        onChange={handleUserConfirmation}
                      />
                      <Form.Check
                        type={"radio"}
                        label={t("Female")}
                        value={
                          formData?.confirmation?.pgender
                            ? formData?.confirmation?.pgender
                            : "Female"
                        }
                        name="pgender"
                        onChange={handleUserConfirmation}
                      />
                    </div>
                    {/* <select
                      className="form-control selectContainer"
                      id="a"
                      type="text"
                      placeholder=""
                      name="pgender"
                      value={
                        formData?.confirmation?.pgender
                          ? formData?.confirmation?.pgender
                          : ""
                      }
                      onChange={handleUserConfirmation}
                    >
                      <option>{t("Select")}</option>
                      <option value={"Male"}>{t("Male")}</option>
                      <option value={"Female"}>{t("Female")}</option>
                    </select> */}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label> {t("Date of Birth")}</Form.Label>
                    <SelectDatepicker
                      order="day/month/year"
                      selectedDate={pvalue}
                      onDateChange={onPDateChange}
                      className="dob-picker"
                      monthPlaceholder={"Month"}
                    />
                  </Form.Group>
                </div>
                <Form.Check
                  type="checkbox"
                  label={t("Issue the invoice name for patient")}
                  className={lang === "EN" ? "checkleft" : "checkright"}
                  checked={
                    formData?.confirmation?.issueInvoice
                      ? formData?.confirmation?.issueInvoice
                      : false
                  }
                  onChange={handleConfirmation}
                  name="issueInvoice"
                />
              </>
            )}
            <Form.Check
              type="checkbox"
              label={
                <span>
                  {" "}
                  {t("I have read and I accept the terms of")}{" "}
                  <a href="/consent" target="_blank">
                    {" "}
                    {t("informed consent")}
                  </a>{" "}
                </span>
              }
              name="consentDocument"
              className={lang === "EN" ? "checkleft" : "checkright"}
              checked={
                formData?.confirmation?.consentDocument
                  ? formData?.confirmation?.consentDocument
                  : false
              }
              onChange={handleConfirmation}
            />
            <Form.Check
              type="checkbox"
              label={
                <span>
                  {" "}
                  {t("I have read and agree to the")}{" "}
                  <a href="/terms" target="_blank">
                    {" "}
                    {t("terms of  use")}
                  </a>
                </span>
              }
              name="termsAndConditions"
              className={lang === "EN" ? "checkleft" : "checkright"}
              checked={
                formData?.confirmation?.termsAndConditions
                  ? formData?.confirmation?.termsAndConditions
                  : false
              }
              onChange={handleConfirmation}
            />
            <Form.Check
              type="checkbox"
              label={
                <span>
                  {" "}
                  {t("I have read and agree to the terms of")}{" "}
                  <a href="https://hidoc.co.il/privacy" target="_blank">
                    {" "}
                    {t("Privacy Policy")}
                  </a>
                </span>
              }
              name="privacyPolicy"
              className={lang === "EN" ? "checkleft" : "checkright"}
              checked={
                formData?.confirmation?.privacyPolicy
                  ? formData?.confirmation?.privacyPolicy
                  : false
              }
              onChange={handleConfirmation}
            />
            <Form.Check
              type="checkbox"
              checked={
                formData?.confirmation?.zoomStatus
                  ? formData?.confirmation?.zoomStatus
                  : false
              }
              name="zoomStatus"
              onChange={handleConfirmation}
              className={lang === "EN" ? "checkleft" : "checkright"}
              label={
                <>
                  {t("I confirm that the")} &nbsp; ZOOM &nbsp;{" "}
                  {t(
                    "application   is installed on the device/computer from which I will make the call"
                  )}
                  <p>
                    {" "}
                    {t(
                      "You have a link to download the software according to the type of operating system:"
                    )}
                  </p>
                  <div className="label-links">
                    <a
                      href="https://zoom.us/client/latest/ZoomInstaller.exe"
                      target={"_blank"}
                    >
                      {t("Windows computer")}
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
                      target={"_blank"}
                    >
                      {t("Android phone/tablet")}
                    </a>
                    <a
                      href="https://itunes.apple.com/us/app/id546505307"
                      target={"_blank"}
                    >
                      {t("Mac / iPhone / iPad")}
                    </a>
                  </div>
                </>
              }
            />
          </>
        </div>
        <div className="almost-done-content">
          <h4> {t(`We're almost done!`)} </h4>

          <h5>
            {" "}
            {t("Click on ״Confirm״ and we will find the right doctor for you!")}
          </h5>
          <h6>
            {" "}
            {t(
              "When found, we will send details for final confirmation and payment."
            )}{" "}
          </h6>
          <p>
            {t(`The cost of the consultation`)} -{" "}
            {utm?.value ? utm?.value : refStep ? "390 ₪" : `${priceamount} ₪`}
          </p>
          <Form.Check
            className={`${lang === "EN" ? "checkleft" : "checkright"} ayalon`}
            type="checkbox"
            label={t(
              "Madness client? Click here for quick processing of the claim"
            )}
            name="madnessConsent"
            onChange={handleMadnessConfirmation}
          />
          {!insuranceConsent ? (
            <Form.Check
              className={`${lang === "EN" ? "checkleft" : "checkright"} ayalon`}
              type="checkbox"
              label={t("ayalonMessage")}
              name="insuranceConsent"
              onChange={handleConfirmation}
            />
          ) : null}
          <div className="action-btn-grid">
            <Button onClick={handleSubmit} disabled={meetingLoader}>
              {t("Send a request for an appointment")}
            </Button>
            <span> {t("Cancel")}</span>
          </div>
        </div>
        <div className="continue-btn with-disclaimer">
          <h5>{t("Information that you have given and will give")}</h5>
        </div>
      </Form>
      <div>
        <div>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modalContent"
          >
            <MDBCard className="modal_card">
              <MDBCardBody>
                {meetingLoader ? (
                  <div className="payloadercontent">
                    <RotatingLines
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="50"
                      visible={meetingLoader}
                    />
                  </div>
                ) : null}
                <MDBCardTitle className="almost">
                  {t(`We're almost done!`)}
                </MDBCardTitle>
                <MDBCardText className="cardText price_font">
                  {" "}
                  {t(
                    "Click on ״Confirm״ and we will find the right doctor for you!"
                  )}
                  {lang === "EN" ? "" : <br></br>}
                  {t("and we will find the right doctor for you!")}
                  <br></br>
                  {t(`The cost of the consultation`)} -{" "}
                  {refStep ? 390 : priceamount || "480"} ₪
                </MDBCardText>
                <div className="aylonMassage">
                  {!insuranceConsent ? (
                    // <div className="check" id="check">
                    //   <input
                    //     type="checkbox"
                    //     onChange={handleConfirmation}
                    //     className={lang === "EN" ? "inputleft" : "inputright"}
                    //   />
                    //   <span className="ayalon_text">
                    //     {" "}
                    //     {t(
                    //       "Ayalon Customer? You are eligible for consultation at the cost of 50 ILS. Check this box to send and verify your ID number with Ayalon."
                    //     )}
                    //   </span>
                    // </div>

                    <Form.Check
                      className={`${lang === "EN" ? "checkleft" : "checkright"
                        } ayalon`}
                      type="checkbox"
                      label={t("ayalonMessage")}
                      name="insuranceConsent"
                      onChange={handleConfirmation}
                    />
                  ) : null}
                </div>

                <div className="concelconfirm">
                  <Button className="buttton_content" onClick={confirm}>
                    {" "}
                    {t("Confirm")}
                  </Button>
                  <span
                    className="final_modal_cancel"
                    onClick={() => setOpen1(false)}
                  >
                    {" "}
                    {t("Cancel")}
                  </span>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Modal>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ marginTop: "10%" }}
          className="otp-modal"
        >
          <Grid container direction="column" alignItems="center">
            <Grid item xs>
              <Paper className="paper" square>
                <Grid container direction="column" alignItems="center">
                  <Grid item lg>
                    <Typography variant="h4">
                      {t("Type a one-time code to identify yourself")}
                    </Typography>
                  </Grid>
                  <Grid item sm>
                    <Typography variant="h6">
                      <span>{phonenumber} </span>
                      {t("An OTP(one time password) has been sent to")}
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    <OtpInput
                      value={otp}
                      onChange={(otp) => {
                        setOtp(otp);
                      }}
                      shouldAutoFocus={true}
                      numInputs={6}
                      inputStyle="otp-input-box"
                      containerStyle={{
                        justifyContent: "center",
                      }}
                      isInputNum
                      whenEntered={true}
                    />
                  </Grid>
                  <Grid container direction="column" alignItems="center">
                    <Grid
                      item
                      xs
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        type="submit"
                        disabled={bisablebutton}
                        onClick={verifyOtp}
                        className="verify-otp-btn"
                      >
                        {t("Approval")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Modal>
      </div>
    </div>
  );
};

export default ConfirmationDetails;
